import { reverseHashKeys, validWords } from "./utils/enums";

export const typeLetter = (letter) => async (dispatch, getState) => {
  const { wordReducer } = getState();
  if (wordReducer.won) return;
  const currentWord = wordReducer.currentWord;

  if (currentWord?.length >= 5) return;

  const newWord = currentWord + letter;
  dispatch({
    type: "TYPE_LETTER",
    payload: newWord,
  });
};
export const removeLetter = () => async (dispatch, getState) => {
  const { wordReducer } = getState();
  if (wordReducer.won) return;
  const currentWord = wordReducer.currentWord;

  const newWord = currentWord.slice(0, -1);
  dispatch({
    type: "REMOVE_LETTER",
    payload: newWord,
  });
};
export const checkWord = () => async (dispatch, getState) => {
  const { wordReducer } = getState();
  if (wordReducer.won) return;
  const currentWord = wordReducer.currentWord;

  if (wordReducer.words.length === 6) return;
  if (currentWord?.length < 5) {
    dispatch({
      type: "ADD_MESSAGE",
      payload: "Not enough letters",
    });
    return;
  }
  if (!validWords.includes(currentWord)) {
    dispatch({
      type: "ADD_MESSAGE",
      payload: "Not in word list",
    });
    return;
  } else if (!wordReducer.answer) {
    dispatch(setAnswer(currentWord));
    return;
  }

  let correctLetters = [];
  let yellowLetters = [];
  let incorrectLetters = [];
  const answerArr = wordReducer.answer.split("");

  const currentWordArr = currentWord.split("");
  for (let x = 0; x < currentWordArr.length; x++) {
    if (answerArr[x] === currentWordArr[x]) {
      correctLetters.push(currentWordArr[x]);
    } else if (answerArr.includes(currentWordArr[x])) {
      yellowLetters.push(currentWordArr[x]);
    } else {
      incorrectLetters.push(currentWordArr[x]);
    }
  }

  const allCorrect = [...correctLetters, ...wordReducer.correctLetters];
  const allYellow = [...yellowLetters, ...wordReducer.yellowLetters];
  const allIncorrect = [...incorrectLetters, ...wordReducer.incorrectLetters];
  localStorage.setItem(
    "correctLetters",
    JSON.stringify([...new Set(allCorrect)])
  );
  localStorage.setItem(
    "yellowLetters",
    JSON.stringify([...new Set(allYellow)])
  );
  localStorage.setItem(
    "incorrectLetters",
    JSON.stringify([...new Set(allIncorrect)])
  );
  dispatch({
    type: "UPDATE_LETTERS",
    payload: {
      correctLetters: [...new Set(allCorrect)],
      yellowLetters: [...new Set(allYellow)],
      incorrectLetters: [...new Set(allIncorrect)],
    },
  });

  const wordsArr = wordReducer.words ?? [];

  const words = [...wordsArr, currentWord];
  localStorage.setItem("words", JSON.stringify(words));
  if (words.length === 6) {
    dispatch({
      type: "ADD_MESSAGE",
      payload: `Out of guesses. The answer was "${wordReducer.answer}"`,
    });
  }
  dispatch({
    type: "ADD_WORD",
    payload: words,
  });
  if (currentWord === wordReducer.answer) {
    localStorage.setItem("won", true);
    dispatch(setEndTime());
    dispatch({
      type: "GAME_WON",
    });
    dispatch({
      type: "ADD_MESSAGE",
      payload: winMessages[wordReducer.words.length],
    });
    setTimeout(() => {
      dispatch({
        type: "SHOW_WON_MODAL",
      });
    }, 1000);
  }
};

const winMessages = [
  "Phenomenal",
  "Spectacular",
  "Excellent",
  "Great",
  "Good",
  "Nice",
];

export const setAnswer = (word) => async (dispatch) => {
  if (!validWords.includes(word)) {
    dispatch({
      type: "ADD_MESSAGE",
      payload: "Not in word list. Please try another.",
    });
    return;
  }
  localStorage.setItem("answer", word);
  dispatch({
    type: "SET_ANSWER_USER",
    payload: word,
  });
};

export const setStartTime = () => async (dispatch, getState) => {
  const { timeReducer } = getState();
  let startTime = timeReducer.startTime;
  if (!startTime) {
    startTime = Date.now();
    localStorage.setItem("startTime", startTime);
    dispatch({
      type: "START_TIME",
      payload: startTime,
    });
  }
};
export const setEndTime = () => async (dispatch, getState) => {
  const { timeReducer } = getState();
  let endTime = timeReducer.endTime;
  if (!endTime) {
    endTime = Date.now();
    localStorage.setItem("endTime", endTime);
    dispatch({
      type: "END_TIME",
      payload: endTime,
    });
  }
};

export const parseAnswerString = (answerHash) => async (dispatch, getState) => {
  const { answer: currAnswer } = getState().wordReducer;
  const answerArr = answerHash.match(/.{1,3}/g);
  let answer = "";
  answerArr.forEach((letter) => {
    answer += reverseHashKeys[letter];
  });
  if (currAnswer && currAnswer !== answer) {
    dispatch(resetData());
  }
  localStorage.setItem("answer", answer);
  dispatch({
    type: "SET_ANSWER",
    payload: answer,
  });
  dispatch(setStartTime());
};

export const resetData = () => async (dispatch) => {
  localStorage.removeItem("startTime");
  localStorage.removeItem("endTime");
  localStorage.removeItem("yellowLetters");
  localStorage.removeItem("won");
  localStorage.removeItem("incorrectLetters");
  localStorage.removeItem("words");
  localStorage.removeItem("correctLetters");
  localStorage.removeItem("answer");

  dispatch({
    type: "RESET_STATE",
  });
};
