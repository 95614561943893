import React from "react";
import styled from "styled-components";
import { COLORS } from "../../colors";

const StyledTile = styled.div`
  width: 60px;
  height: 60px;
  margin: 3px;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  background-color: ${({ type }) =>
    type === "correct"
      ? COLORS.correct
      : type === "yellow"
      ? COLORS.yellow
      : type === "incorrect"
      ? COLORS.incorrect
      : "none"};
  border: ${({ type }) =>
  type === "correct"
      ? `2px solid ${COLORS.correct}`
      : type === "yellow"
      ? `2px solid ${COLORS.yellow}`
      : type === "incorrect"
      ? `2px solid ${COLORS.incorrect}`
      : `2px solid ${COLORS.incorrect}`};
`;

const Tile = ({ letter, type }) => {
  return <StyledTile {...{ type }}>{letter?.toUpperCase() ?? ""}</StyledTile>;
};

export default Tile;
