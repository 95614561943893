import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatTime } from "./utils";

const Timer = ({ startTime, endTime }) => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    let interval = null;
    if (startTime && !endTime) {
      interval = setInterval(() => {
        const now = Date.now();
        setTime(now - startTime);
      }, 1000);
    } else if (startTime && endTime) {
        setTime(endTime - startTime)
        clearInterval();
    } else {
      clearInterval(interval);
      setTime(0);
    }
    return () => {
      clearInterval(interval);
      setTime(0);
    };
  }, [startTime, endTime]);
  return time > 0 ? <div>{formatTime(time)}</div> : null;
};

export default connect(({ timeReducer }) => ({
  startTime: timeReducer.startTime,
  endTime: timeReducer.endTime,
}))(Timer);
