import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { COLORS } from "../../colors";
import { validKeys } from "../../utils/enums";
import { typeLetter, checkWord, removeLetter } from "../../actions";


const StyledButton = styled.button`
  width: auto;
  max-width: 100%;
  min-width: 0;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  cursor: pointer;
  background-color: ${({ type }) =>
    type === "correct"
      ? COLORS.correct
      : type === "yellow"
      ? COLORS.yellow
      : type === "incorrect"
      ? COLORS.incorrect
      : COLORS.default};
  &:active {
    background-color: ${({ type }) =>
      type === "correct"
        ? COLORS.correctHover
        : type === "yellow"
        ? COLORS.yellowHover
        : type === "incorrect"
        ? COLORS.incorrectHover
        : COLORS.defaultHover};
  }
`;



const KeyboardKey = ({ letter, icon, typeLetter, checkWord, removeLetter, correctLetters, yellowLetters, incorrectLetters }) => {
  const getType = () => {
      if (correctLetters.includes(letter.toLowerCase())) {
        return 'correct'
      } else if (yellowLetters.includes(letter.toLowerCase())) {
        return "yellow";
      } else if (incorrectLetters.includes(letter.toLowerCase())) {
        return "incorrect";
      } else {
        return "";
      }
  }

  const handleClick = () => {
    if (validKeys.includes(letter.toLowerCase())) {
      typeLetter(letter.toLowerCase())
    } else if (letter === 'Enter') {
      checkWord();
    } else if (letter === 'Backspace') {
      removeLetter();
    }
  }
  return <StyledButton {...{ type: getType() }} onClick={handleClick}>{icon ? icon : letter}</StyledButton>;
};

export default connect(
  ({ wordReducer }) => ({
    correctLetters: wordReducer.correctLetters,
    yellowLetters: wordReducer.yellowLetters,
    incorrectLetters: wordReducer.incorrectLetters,
  }),
  { typeLetter, checkWord, removeLetter }
)(KeyboardKey);
