import React from "react";
import styled from "styled-components";
import { COLORS } from "../../colors";

const StyledButton = styled.button`
  width: 100%;
  height: 40px;
  background-color: ${COLORS.button};
  border: none;
  margin: 5px 0;
  color: var(--text-color);
  font-size: 20px;
  border-radius: 5px;
  font-weight: 600;
  
`;

const Button = ({ children, onClick }) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};

export default Button;
