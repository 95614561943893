import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { wordReducer, messageReducer, timeReducer } from "./reducers";

const initialState = {
  wordReducer: {
    currentWord: "",
    words: localStorage.getItem("words")
      ? JSON.parse(localStorage.getItem("words"))
      : [],
    answer: localStorage.getItem("answer")
      ? localStorage.getItem("answer") : "",
    won: localStorage.getItem("won") ? localStorage.getItem("won") : false,
    correctLetters: localStorage.getItem("correctLetters")
      ? JSON.parse(localStorage.getItem("correctLetters"))
      : [],
    yellowLetters: localStorage.getItem("yellowLetters")
      ? JSON.parse(localStorage.getItem("yellowLetters"))
      : [],
    incorrectLetters: localStorage.getItem("incorrectLetters")
      ? JSON.parse(localStorage.getItem("incorrectLetters"))
      : [],
    showShareLinkModal: false,
    showWonModal: localStorage.getItem("won") ? true : false,
  },
  timeReducer: {
    startTime: localStorage.getItem("startTime")
      ? localStorage.getItem("startTime")
      : null,
    endTime: localStorage.getItem("endTime")
      ? localStorage.getItem("endTime")
      : null,
  },
};
const reducer = combineReducers({
  wordReducer,
  messageReducer,
  timeReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
