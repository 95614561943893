import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import styles from "./index.module.css";

const Modal = ({ show, handleClose, children }) => {
  return (
    <BootstrapModal show={show} onHide={handleClose} centered>
      <div className={styles.modal}>
        <div className={styles.closeContainer}>
          <div onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} className={styles.closeIcon} />
          </div>
        </div>
        {children}
      </div>
    </BootstrapModal>
  );
};

export default Modal;
