import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import KeyboardKey from "./keyboardKey";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(7, 1fr) 1.5fr;
  grid-gap: 5px;
  padding: 5px 10px;
  width: 100%;
  max-width: 100%;
`;

const keys = ["Z", "X", "C", "V", "B", "N", "M"];

const BottomKeyboard = () => {
  return (
    <GridContainer>
      <KeyboardKey {...{ icon: 'ENTER', letter: "Enter" }} />
      {keys.map((key, index) => {
        return <KeyboardKey {...{ letter: key }} key={index} />;
      })}
      <KeyboardKey {...{ icon: <FontAwesomeIcon icon={faDeleteLeft} />, letter: 'Backspace' }} />
    </GridContainer>
  );
};

export default BottomKeyboard;
