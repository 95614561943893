import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import Keyboard from "./components/keyboard/keyboard";
import Nav from "./components/nav/nav";
import Words from "./components/word/words";
import { validKeys } from "./utils/enums";
import {
  typeLetter,
  removeLetter,
  checkWord,
  setAnswer,
  parseAnswerString,
  resetData,
} from "./actions";
import SetWordModal from "./components/setWord/setWordModal";
import SetWord from "./components/setWord";
import { useLocation, useNavigate } from "react-router-dom";
import CopyLinkModal from "./components/modals/copyLinkModal";
import { reverseHash } from "./utils";
import WonModal from "./components/modals/wonModal";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const App = ({
  typeLetter,
  removeLetter,
  checkWord,
  answer,
  parseAnswerString,
  resetData,
}) => {
  let query = useQuery();
  const navigate = useNavigate();
  const parsedAnswer = query.get("a");

  useEffect(() => {
    if (parsedAnswer && !answer) {
      parseAnswerString(parsedAnswer);
    }
  }, [parsedAnswer, answer, parseAnswerString]);

  useEffect(() => {
    if (answer) {
      document.title = "Guess My Word! | Friendle";
    }
  }, [answer]);

  useEffect(() => {
    if (
      (!answer && !parsedAnswer) ||
      (answer && parsedAnswer && answer !== reverseHash(parsedAnswer))
    ) {
      resetData();
      navigate("/");
    }
  }, [answer, resetData, parsedAnswer, navigate]);

  const handleKeyType = (e) => {
    const key = e.key;
    if (validKeys.includes(key)) {
      typeLetter(key);
    } else if (key === "Backspace") {
      removeLetter();
    } else if (key === "Enter") {
      checkWord();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyType);

    return () => document.addEventListener("keydown", handleKeyType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="app-container">
        <Nav />
        <div className="content-container">
          {answer ? <Words /> : <SetWord />}
        </div>
        <div className="keyboard-container">
          <Keyboard />
        </div>
      </div>
      <SetWordModal />
      <CopyLinkModal />
      <WonModal />
    </>
  );
};

export default connect(
  ({ wordReducer }) => ({
    answer: wordReducer.answer,
  }),
  {
    typeLetter,
    removeLetter,
    checkWord,
    setAnswer,
    parseAnswerString,
    resetData,
  }
)(App);
