import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../colors";
import NewGameModal from "../modals/newGameModal";
import Timer from "../timer";

const StyledNav = styled.nav`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.incorrect};
  align-items: center;
`;

const StyledTitle = styled.div`
  padding: 10px 0;
  font-size: 30px;
  font-weight: bold;
  color: var(--text-color);
`;

const IconContainer = styled.div`
  width: 70px;
  padding: 0 10px;
  color: var(--text-color);
  font-size: 26px;
  cursor: pointer;
  text-align: right;
`;
const TimerContainer = styled.div`
  width: 70px;
  padding: 0 10px;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 600;
`;

const Nav = () => {
  const [showModal, setShowModal] = useState(false);
  const openNewGameModal = () => {
    setShowModal(true);
  };
  return (
    <>
      <StyledNav>
        <TimerContainer>
          <Timer />
        </TimerContainer>
        <StyledTitle>Friendle</StyledTitle>
        <IconContainer>
          <FontAwesomeIcon icon={faPlus} onClick={openNewGameModal} />
        </IconContainer>
      </StyledNav>
      <NewGameModal show={showModal} handleClose={() => setShowModal(false)} />
    </>
  );
};

export default Nav;
