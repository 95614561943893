import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Tile from "./tile";

const Container = styled.div`
  display: flex;
`;

const frequency = (arr, letter) => {
  return arr.filter((x) => x === letter).length;
};

const WordRow = ({ rowIndex, wordsArr, answer, word }) => {

  const getType = (letter, index) => {
    if (!letter || rowIndex >= wordsArr.length) return;
    const answerArr = answer?.split("");
    const otherLetters = word?.split('').filter((_, letterIndex) => letterIndex !== index) ?? [];
    if (answerArr[index] === letter) {
      return "correct";
    } else if (frequency(answerArr, letter) > 0) {
      if (frequency(answerArr, letter) > frequency(otherLetters, letter)) {
        return "yellow";
      } else {
        return "incorrect";
      }
    } else {
      return "incorrect";
    }
  };

  return (
    <Container>
      {Array(5)
        .fill()
        .map((_, index) => {
          return (
            <Tile
              key={index}
              letter={word[index] ?? ""}
              type={getType(word[index], index)}
            />
          );
        })}
    </Container>
  );
};

export default connect(({ wordReducer }) => ({
  wordsArr: wordReducer.words,
  answer: wordReducer.answer,
}))(WordRow);
