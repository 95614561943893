import React from 'react';
import WordRow from './wordRow';
import styled from 'styled-components'
import Message from '../message';
import { connect } from 'react-redux';

const WordRowContainer = styled.div`
    position: relative;
`

const AlertContainer = styled.div`
position: absolute;
top: -50px;
width: 100%;
display: flex;
justify-content: center;
`

const Words = ({words, currentWord}) => {
    const getWord = (index) => {
      if (words?.[index]) {
        return words[index]
      } else if (words.length === index) {
        return currentWord;
      } else {
        return '';
      }
    }
    return (
      <WordRowContainer>
        <AlertContainer>
          <Message />
        </AlertContainer>
        {Array(6).fill().map((_, index) => {
          return <WordRow {...{ rowIndex: index, word: getWord(index), key: index }} />;
        })}
      </WordRowContainer>
    );
};

export default connect(({ wordReducer }) => ({
  currentWord: wordReducer.currentWord,
  words: wordReducer.words,
}))(Words);