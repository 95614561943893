import React from "react";
import styled from "styled-components";
import { COLORS } from "../../colors";

const StyledInput = styled.input`
  width: 100%;
  border: 2px solid ${COLORS.incorrect};
  color: #fff;
  border-radius: 8px;
  height: 35px;
  padding: 10px;
  background-color: transparent;
  outline: none;
`;

const Input = ({ value, onChange }) => {
  return (
    <div>
      <StyledInput value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export default Input;
