import React from "react";
import styled from "styled-components";
import KeyboardKey from "./keyboardKey";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 5px;
  padding: 0px 30px;
  width: 100%;
  max-width: 100%;
`;

const keys = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];

const MiddleKeyboard = () => {
  return (
    <GridContainer>
      {keys.map((key, index) => {
        return <KeyboardKey {...{ letter: key }} key={index} />;
      })}
    </GridContainer>
  );
};

export default MiddleKeyboard;
