import React from "react";
import styled from "styled-components";
import KeyboardKey from "./keyboardKey";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 5px;
  padding: 5px 10px;
  width: 100%;
  max-width: 100%;
`;

const keys = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];

const TopKeyboard = () => {
  return (
    <GridContainer>
      {keys.map((key, index) => {
        return <KeyboardKey {...{ letter: key }} key={index} />;
      })}
    </GridContainer>
  );
};

export default TopKeyboard;
