import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { generateHash } from "../../utils";
import { setStartTime } from "../../actions";
import Button from "../button";
import Modal from "../modal";
import styles from "../modal/index.module.css";

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const CopyLinkModal = ({ showModal: show, answer, setStartTime }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [hash, setHash] = useState("");
  const closeModal = () => {
    dispatch({ type: "HIDE_SHARE_LINK_MODAL" });
    navigate(`/${hash}`);
  };

  const copyText = () => {
    navigator.clipboard.writeText(window.location + hash);
    setCopied(true);
    setTimeout(() => {
      closeModal();
      setStartTime();
    }, 200);
  };
  useEffect(() => {
    if (show && answer) {
      const href = generateHash(answer);
      setHash(href);
    }
  }, [show, answer]);

  return (
    <Modal {...{ show: show, handleClose: closeModal }}>
      <div className={styles.title}>Share your Link</div>
      <ButtonContainer>
        <Button onClick={copyText}>{copied ? "Copied!" : "Copy Link"}</Button>
      </ButtonContainer>
    </Modal>
  );
};

export default connect(
  ({ wordReducer }) => ({
    showModal: wordReducer.showShareLinkModal,
    answer: wordReducer.answer,
  }),
  { setStartTime }
)(CopyLinkModal);
