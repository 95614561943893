import { hashKeys, reverseHashKeys } from "./enums";

export const generateHash = (word) => {
  let hash = "";
  const wordArr = word.split("");
  for (let letter in wordArr) {
    hash += hashKeys[wordArr[letter]];
  }
  return `?a=${hash}`;
};


export const reverseHash = (answerHash) => {
  if (!answerHash) return;
  const answerArr = answerHash?.match(/.{1,3}/g);
  let answer = "";
  answerArr.forEach((letter) => {
    answer += reverseHashKeys[letter] ?? '';
  });

  return answer
}