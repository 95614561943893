export const COLORS = {
  correct: "#26a100",
  correctHover: "#1f8200",
  yellow: "#bdaa00",
  yellowHover: "#9d8d00",
  incorrect: "#333333",
  incorrectHover: "#212121",
  default: "#7a7a7a",
  defaultHover: "#6a6a6a",
  messageColor: "#fff",
  button: "#007be0",
};