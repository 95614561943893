import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Button from "../button";
import Message from "../message";
import WordRow from "../word/wordRow";
import { checkWord } from "../../actions";

const Card = styled.div`
  padding: 15px;
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
`;
const MessageContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 5px 0;
`;

const SetWord = ({ currentWord, checkWord }) => {
  return (
    <div className={"d-flex flex-column"}>
      <MessageContainer>
        <Message />
      </MessageContainer>
      <Card>
        <div className="title">Welcome to Friendle!</div>
        <div className="pt-3 d-flex flex-column align-items-center">
          <WordRow {...{ word: currentWord }} />
          <div style={{ width: "325px" }} className='mt-2'>
            <Button onClick={() => checkWord()}>Set Answer</Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default connect(
  ({ wordReducer }) => ({
    currentWord: wordReducer.currentWord,
  }),
  { checkWord }
)(SetWord);
