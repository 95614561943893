import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { COLORS } from "../../colors";

const MessageContainer = styled.div`
  ${({ show }) =>
    show
      ? `visibility: visible; opacity: 1;`
      : `visibility: hidden; opacity: 0;`}
  transition: 0.2s;
  background-color: ${COLORS.messageColor};
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
`;

const Message = ({ message }) => {
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (message) {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false)
      }, 2000);
      setTimeout(() => {
        dispatch({
          type: "RESET_MESSAGE",
        });
      }, 2500);
    }
  }, [message, dispatch]);

  return (
    <MessageContainer {...{ show: showMessage }}>{message}</MessageContainer>
  );
};

export default connect(({ messageReducer }) => ({
  message: messageReducer.message,
}))(Message);
