import React, { useState } from "react";
import { connect } from "react-redux";
import { validKeys } from "../../utils/enums";
import Input from "../input";
import Modal from "../modal";
import styles from "../modal/index.module.css";

const SetWordModal = ({ answer }) => {
  const [show, setShow] = useState(false);
  const [word, setWord] = useState('')
  const closeModal = () => {
    setShow(false);
  };

//   useEffect(() => {
//     if (!answer && !show) {
//       setShow(true);
//     } else if (answer && show) {
//       setShow(false);
//     }
//   }, [answer, show]);

  const handleWordChange = (text) => {
    if (text.length > 5) return;
    for (let x = 0; x< text.length; x++) {
        if (!validKeys.includes(text[x].toLowerCase())) {
            return;
        }
    }
    setWord(text)
  }

  return (
    <Modal {...{ show: show, handleClose: closeModal }}>
      <div className={styles.title}>Welcome To Friendle!</div>
      <div>
        <Input value={word} onChange={handleWordChange} />
      </div>
    </Modal>
  );
};

export default connect(({ wordReducer }) => ({ answer: wordReducer.answer }))(
  SetWordModal
);
