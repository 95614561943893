import React from 'react';
import BottomKeyboard from './bottomKeyboard';
import MiddleKeyboard from './middleKeyboard';
import TopKeyboard from './topKeyboard';

const Keyboard = () => {
    return (
        <>
            <TopKeyboard />
            <MiddleKeyboard />
            <BottomKeyboard />
        </>
    );
};

export default Keyboard;