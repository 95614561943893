const INITIAL_STATE = {
  currentWord: "",
  words: [],
  won: false,
  correctLetters: [],
  yellowLetters: [],
  incorrectLetters: [],
  answer: "",
  showShareLinkModal: false,
  showWonModal: false,
};

export const wordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TYPE_LETTER":
    case "REMOVE_LETTER":
      return {
        ...state,
        currentWord: action.payload,
      };
    case "ADD_WORD":
      return {
        ...state,
        words: action.payload,
        currentWord: "",
      };
    case "GAME_WON":
      return {
        ...state,
        won: true,
      };
    case "UPDATE_LETTERS":
      return {
        ...state,
        correctLetters: action.payload.correctLetters,
        yellowLetters: action.payload.yellowLetters,
        incorrectLetters: action.payload.incorrectLetters,
      };
    case "SET_ANSWER":
      return {
        ...state,
        answer: action.payload,
        currentWord: "",
      };
    case "SET_ANSWER_USER":
      return {
        ...state,
        answer: action.payload,
        currentWord: "",
        showShareLinkModal: true,
      };
    case "HIDE_SHARE_LINK_MODAL":
      return {
        ...state,
        showShareLinkModal: false,
      };
    case "SHOW_WON_MODAL":
      return {
        ...state,
        showWonModal: true,
      };
    case "HIDE_WON_MODAL":
      return {
        ...state,
        showWonModal: false,
      };
    case "RESET_STATE":
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export const messageReducer = (state = { message: "" }, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return { message: action.payload };
    case "RESET_MESSAGE":
      return {};
    default:
      return state;
  }
};


export const timeReducer = (
  state = { startTime: null, endTime: null },
  action
) => {
  switch (action.type) {
    case "START_TIME":
      return { ...state, startTime: action.payload };
    case "END_TIME":
      return { ...state, endTime: action.payload };
    case "RESET_STATE":
      return { startTime: null, endTime: null };
    default:
      return state;
  }
};
