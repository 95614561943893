import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "../button";
import Modal from "../modal";
import styles from "../modal/index.module.css";
import { formatTime } from "../timer/utils";

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;
const frequency = (arr, letter) => {
  return arr.filter((x) => x === letter).length;
};

const StyledAnswerContainer = styled.div`
  white-space: pre;
  color: var(--text-color);
  font-weight: 600;
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 22px;
`;

const WonModal = ({ show, answer, words, startTime, endTime }) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [dataToCopy, setDataToCopy] = useState("");
  const closeModal = () => {
    dispatch({ type: "HIDE_WON_MODAL" });
  };

  const getType = (letter, index, word) => {
    const answerArr = answer?.split("");
    const otherLetters =
      word?.split("").filter((_, letterIndex) => letterIndex !== index) ?? [];
    if (answerArr[index] === letter) {
      return "🟩";
    } else if (frequency(answerArr, letter) > 0) {
      if (frequency(answerArr, letter) > frequency(otherLetters, letter)) {
        return "🟨";
      } else {
        return "⬛️";
      }
    } else {
      return "⬛️";
    }
  };

  const getGuesses = () => {
    let data = "";
    data += 'Friendle\n'
    data += words.length + "/6 \n";
    words.forEach((word) => {
      let str = "";
      const wordArr = word.split("");
      wordArr.forEach((letter, index) => {
        str += getType(letter, index, word);
      });
      data += str + "\n";
    });
    if (startTime && endTime) {
      const time = formatTime(endTime - startTime);
      data += 'Time: ' + time;
    }
    setDataToCopy(data);
  };

  useEffect(() => {
    getGuesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, words]);

  const copyText = () => {
    navigator.clipboard.writeText(dataToCopy);
    setCopied(true);
    setTimeout(() => {
      closeModal();
    }, 300);
  };

  return (
    <Modal {...{ show: show, handleClose: closeModal }}>
      <div className={styles.title}>Well Done!</div>
      <StyledAnswerContainer>{dataToCopy}</StyledAnswerContainer>
      <ButtonContainer>
        <Button onClick={copyText}>{copied ? "Copied!" : "Copy"}</Button>
      </ButtonContainer>
    </Modal>
  );
};

export default connect(({ wordReducer, timeReducer }) => ({
  show: wordReducer.showWonModal,
  answer: wordReducer.answer,
  words: wordReducer.words,
  startTime: timeReducer.startTime,
  endTime: timeReducer.endTime,
}))(WonModal);
