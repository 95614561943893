import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../button";
import Modal from "../modal";
import styles from "../modal/index.module.css";
import { resetData } from "../../actions";

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const WonModal = ({ show, handleClose, resetData }) => {
  const navigate = useNavigate();

  const closeModal = () => {
    handleClose();
  };

  const startNewGame = () => {
    resetData();
    navigate('/');
    closeModal();
  };

  return (
    <Modal {...{ show: show, handleClose: closeModal }}>
      <div className={styles.title}>New Game?</div>
      <ButtonContainer>
        <Button onClick={startNewGame}>Start New Game</Button>
      </ButtonContainer>
    </Modal>
  );
};

export default connect(null, {
  resetData
})(WonModal);
